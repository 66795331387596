/* Webfont: Lato-Regular */@font-face {
    font-family: 'LatoWeb';
    src: url('../../fonts/Lato/Lato-Regular.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */@font-face {
    font-family: 'LatoWeb';
    src: url('../../fonts/Lato/Lato-Bold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-Bold.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */@font-face {
    font-family: 'LatoWeb';
    src: url('../../fonts/Lato/Lato-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato/Lato-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */@font-face {
    font-family: 'LatoWeb';
    src: url('../../fonts/Lato/Lato-Italic.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Lato/Lato-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Lato/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-Italic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Lato/Lato-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
