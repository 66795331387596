.ui-state-focus .searchDescription {
  color: #ddd;
}

.searchResult {
  a {
    border-bottom: solid 1px black;
    font-size: 0.8em;
    display: block;

    .searchTitle {
      font-weight: bold;
      font-size: 1.2em;
    }

    .searchDescription {
      color: #999;

      em {
        font-weight: bold;
        color: black;
      }
    }

    &:hover .searchDescription {
      color: white;
      em {
        color: white;
      }
    }
  }
}

.searchResult:last-child {
  border-bottom: none;
}

.projectViewer input.projectSearch {
  float: right;
  margin-top: 20px;
}
