.organizationReportBody {
    min-height: 320px;

    .organizationReportCanvas {
        width: 300px;
        height: 300px;
        float: left;
        margin: 10px 10px 0 0;
    }

    .organizationReportTableWrapper {
        font-size: 0.8em;
    }
}

.reportsHeader {
    margin-bottom: 0;
    margin-top: 0;
}

.reportsDownloadContainer {
    margin-top: 0.5em;
    margin-bottom: 1em;
}

#organizationReportsTabset {
    ul {
        font-size: 0.7em;
    }

    .ui-tabs-panel {
        padding: 10px;
    }
}

.projectPickerWrapper, .portalPickerWrapper,
.downloadProjectPickerWrapper, .downloadPortalPickerWrapper {
    display: inline-block;
    padding-left: 1em;
    vertical-align: bottom;
}

.orgEntityCountNote {
    display: block;
    text-align: center;
    font-size: smaller;
}

.organizationChartHeader .datePickerWrapper {
    display: inline-block;
    vertical-align: bottom;
}

#projectCheckboxList,#portalCheckboxList,
#downloadProjectCheckboxList,#downloadPortalCheckboxList {
    height: 4em;
    border: solid thin gray;
    overflow-y: scroll;
    label {
        display: block;
    }
}

.downloadFileSizeNote {
    margin-left: 2em;
    margin-right: 2em;
    font-size: smaller;
}
    

