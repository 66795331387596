.ldapConfigEditor, .oauth2ConfigEditor {
    .leftSide {
        float: left;
        width: 300px;
    }

    .rightSide {
        float: left;
        width: 300px;
    }

    .leftSide, .rightSide {
      input[type=text], input[type=number], input[type=password], input[type=url] select, textarea {
        width: 280px;
      }
    }

    .groupFilterConfig {
        display: none
    }

    p {
        font-size: .8em;
    }
}

.oauth2ConfigEditor {
    .grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .input-group {
        display: flex;
        gap: 0.4rem;
        width: 100%;
    }

    .grid input {
        box-sizing: border-box;
        width: 100%;
        font-size: .8rem;
        padding: .25rem;
    }

    .grid > * {
        margin-bottom: unset;
    }
}

.samlConfigEditorContents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: .25rem;

    .fullWidth {
        grid-column: span 2;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .signAuthorizationRequests {
        line-height: 1.5rem;
    }
}

.ldapConfigEditorGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    textarea, input[type=text], input[type=number],
    input[type=password], input[type=text], select {
        box-sizing: border-box;
        width: 100%;
        min-width: 248px;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .flex > input {
        flex: 0;
    }
}

.ldapConfigEditorGroup > * {
    flex: 1;
}

.ldapConfigErrorReport {
    font-size: .8em;
}

#portalSecurityConfig {
    label {
        font-weight: bold;
        display: block;

        &.radioLabel {
          display: inline;
        }
    }

    h3 {
        margin-bottom: 0;
    }

    p.description {
        font-size: .8em;
        font-style: italic;
        margin-top: 0;
        width: 500px;
    }

    .filterTabs > ul {
        font-size: .7em;
    }

    .filterList {
        padding: 20px;

        @add-button-margin: 1em;

        .deleteButton {
            width: 18px !important;
            height: 18px !important;
            margin-right: 10px;
        }

        .all {
            font-style: italic;
            color: @gray;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            margin-top: 0;

            li {
                margin-bottom: .2em;
            }
        }

        .all, ul {
            margin: 1em 0;
        }
    }
}

.ldapSearchDialog, .samlConfigEditor, .samlImportDialog, .oauth2ConfigEditor, .oauth2ImportDialog {
    .ldapResults {
        height: 200px;
        .dialogFormField;
    }
}

.samlImportDialog input[type=radio] {
  float: left;
}


#moderatorHeading {
	display: inline-block;
}

textarea#samlCert {
	font-size: 10px;
	height: 130px;
    width: 100%;
    box-sizing: border-box;
}

.spMdLink {
  display: flex;
  gap: 10px;
  
  font-size: .8em;
  margin-top: 10px;
  text-align: right;

  a, a:visited {
    color: #999;
  }
}
