.printonly, .printPageHeading {
  display: none;
  visibility: hidden;
}

@media print {
  .printonly, .printPageHeading {
    display: initial;
    visibility: visible;
  }

  .printPageHeading {
    margin: 0;
  }

  .noprint {
    display: none !important;
  }

  .loginHeaderLink {
    display: none;
  }

  .tabbody {
    height: auto !important;
  }

  .framesetUI {
    span.heading {
      float: none;
    }

    .frame-container {
      position: relative;
      left: 0;
      top: 0;
    }
  }

  .projectViewer {
    .frame-right {
      .ui-tabs-nav {
        display: none;
      }
    }
  }
}