.mdSelectContainer, .mdInputmdInputContainer {
  width:100%;
}

.Delete {
  float:right;
}

.mdSchemaHelpText {
  font-size: .9em;
  font-style: italic;
}

.orgMetadataSchemaConfig {
  display: inline-block;
  font-size: .8em;
  .orgMetadataTable {
    min-width: 600px;
    tbody td {
      vertical-align: top;
    }
    tbody > tr {
      >td {
        border-bottom: solid 1px #ccc;
        padding-bottom: 5px;
      }
      &:last-of-type>td {
        padding-bottom: 10px;
        border-bottom: none;
      }
    }
    tfoot td {
      padding-top: 5px;
      border-top: solid 2px black;
    }

    input, select {
      &.mdDisplayName {
        width: 250px;
      }
    }
  }
}

.SchemaBasedMDEditor {
  .field {
    clear: both;
    padding-top: 1em;
  }

  div.label {
    float: left;
    width: 300px;
    margin-right: 10px;

    label>span {
      display: block;
      width: 100%;
      text-align: right;

      &.displayName {
        font-weight: bold;
        font-size: 1.2em;
      }

      &.rawname {
        font-size: .8em;
      }
    }
  }

  div.valueEditor {
    float: left;

    ul {
      margin-top: 0;
      margin-bottom: 0;
    }

    input {
      width: 300px;
    }

    select {
      min-width: 300px;
    }
  }

  .buttons {
    clear: both;
    width: 800px;
    text-align: right;
    margin: 10px 0;
  }
}