.unknownContentInfoForm {
    width: 500px;
    
    margin-top: -20px;
    
    &.inputOnly {
        margin-top: 0;
    }
    
    label {
        display: block;
        font-weight: bold;
        margin-top: 5px;
        
        small {
            font-weight: normal;
            font-style: italic;
        }
    }
    
    input, textarea {
        width: 100%;
    }
    
    textarea {
        font-family: @font-body !important;
        padding: 4px;
        border: solid 1px black;
    }
    
    .unknownButtons {
        margin-top: 8px;
        text-align: right;
    }
}