.auditLogTable {
  margin: 5px;
  width: 100%;
  font-size: .8em;

  .when {
    font-weight: bold;
    color: green;
  }

  .who {
    font-weight: bold;
  }
}

.auditLogEvent {
  .header {
    margin-bottom: 1em;
  }

  .diffLabel {
    margin: 10px 0;
    background: @harp-gray;
  }

  .diff {
    max-height: 300px;
    overflow: auto;
    font-size: .9em;

    .diffEntry {
      margin-bottom: 10px;
    }

    .key {
      font-weight: bold;
    }

    .oldValue {
      color: @gray;
    }

    .newValue {
      color: green;
    }
  }
}
