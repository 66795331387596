.itemContentsContainer {
	width: 100%;
	
	tr {
		font-size: .9em;
	}
	
	.ui-icon {
		display: inline-block;
	}
	
	.dataTables_info {
		font-size: .9em;
	}
	
	.itemContentsHidden {
		display: none;
	}
	
	.contentsLoadingProgress {
		display: block;
		width: 100px;
		height: 100px;
		margin-left: auto;
		margin-right: auto;
	}
}

.projectTransformsTable {
    margin: 1em;
}

.projectDetailsTabset {
    background-color: white !important;
    background: inherit !important;
}

.projectDoctypesConfig {
  padding: 1em;
  .projectDoctypeList {
    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;
  }
}

#toggleValidationRollup {
    font-size: .6em;
    font-color: blue;
    text-decoration: underline;
}

.validationRecordIndicator {
    font-size: .6em;
    font-color: lightgray;
}
