#publicHeader {
    background-color: @header-bg;
    padding: 3px;
    border-bottom: @header-border;
    height: 100px;
}

#publicLogo {
    font-size: 40px;
    color: @header-logo-color;
    font-family: @font-title;
    text-decoration: none;
}

#publicLogo:visited {
    color: @header-logo-color;
}

#appHeader {
    background-color: @header-bg;
    padding: 3px;
    border-bottom: @header-border;
    height: 24px;
}

#headerProductName {
    font-family: @font-title;
    font-size: 18px;
    color: @header-logo-color;
    margin-right: 20px;
    padding-left: 20px;
    text-decoration: none;
}

#headerProductName:visited {
    color: @header-logo-color;
}

#appHeaderRight {
    display: inline;
    float: right;
    margin-right: 20px;
}

#appHeaderLeft {
    display: inline;
    float: left;
}

#userGreeting {
    color: @header-color;
    font-size: 12px;
    margin-right: 12px;
}

.loginHeaderLink {
    font-size: 10px;
    color: @header-color;
}

.loginHeaderLink:visited {
    color: @header-color;
}

#harpAppFooter {
    font-size: 10px;
    background-color: @header-bg;
    color: @header-color;
    height: 15px;
    position: fixed;
    bottom: 0px;
    padding: 0 6px 0 6px;
    width: 100%;
    z-index: 1000;
}

#harpAppFooter a,#harpAppFooter a:visited {
    color: @header-color;
    text-decoration: none;
}

#harpAppFooter .build {
    float: right;
    margin-right: 20px;
}
