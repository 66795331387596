#accountDetailsContainer {
    margin-top: 1em;
    margin-left: .5em;
    
    #contactDetailsTable {
    
        .contactDetailsLabel {
            width: 10em;
            font-weight: bold;
            font-size: .9em;
        }
        
        .contactDetailsValue {
            font-size : .9em;
        }
    }
    
    #editAccountDetailsContainer {
        margin-top: 1em;
        
        .accountEditor {
            padding: 5px;
            margin-top: 6px;
            margin-bottom: 6px;
            text-decoration: underline;
        }
        
        .editorHover {
            background-color: @gray-lighter;
            cursor: pointer;
        }    
    }    
}