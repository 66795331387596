@loader-dark: @harp-blue;
@loader-light: lighten(@harp-blue, 40%);
@loader-weight: 6px;

.loadingIndicator, .loadingIndicator:after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  overflow: hidden;
}

.loadingIndicator {
  font-size: 10px;
  text-indent: -9999em;
  border-top: @loader-weight solid @loader-light;
  border-right: @loader-weight solid @loader-light;
  border-bottom: @loader-weight solid @loader-light;
  border-left: @loader-weight solid @loader-dark;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader-animation 1.1s infinite linear;
  animation: loader-animation 1.1s infinite linear;
}

#appLoadingIndicator {
  position: absolute;
  right: 10px;
  top: 40px;
  display: none;
}

@-webkit-keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.negativeStyle {
  border: solid 1px @negative-color;
  background: @negative-color;
}

.ui-dialog.error {
  .ui-dialog-titlebar,
  .ui-state-default {
    .negativeStyle;
  }
}

.ui-button.negativeButton {
  .negativeStyle;
  &.ui-state-hover {
    background: lighten(@negative-color, 10%);
  }
}

span.ui-icon.negativeButton {
  color: @negative-color;
}

.previewTooBig {
  .showMeAnyway, .showMeAnyway:visited {
    color: red;
  }
}
