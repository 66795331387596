#mainCommentList, #elementLevelCommentList {
  .tabHeading {
    height: 25px;
    .commentManagementButtons {
      float: right;
      margin-right: 15px;
    }
  }
}

ul.commentList {
  list-style-type: none;
  padding-left: 15px;
}

ul.replyList {
  list-style-type: none;
}
.displayRepliesButton {
  display: inline;
}
p.commentDate {
  display: inline;
}
h2.commentListHeading {
  margin-left: 15px;
  font-weight: normal;
}

select.dispositionMenu {
  width: 150px;
}

.commentContainer {
  background-color: @harp-gray;
  padding: 5px 15px 20px 15px;
  border-radius: 5px;
  margin: 0 15px 15px 0;

  .commentAuthor {
    margin: 10px 0;
  }

  &.old .commentLastModified {
    color: red;
  }
  
  .commentModerationFeatures {
    float: right;
    span.ui-selectmenu-button {
      top: 0;
      height: 13px;
    }
  }

  .commentBody {
    background-color: lighten(@harp-gray, 10%);
    width: 80%;
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
    color: black;

    > p:first-child {
      margin-top: 0;
    }
    > p:last-child {
      margin-bottom: 0;
    }
  }
  
  .commentInfo {
    width: 20%;
    float: right;
    font-size: .8em;
    padding: 0 10px;
    box-sizing: border-box;
    p {
      margin: 0;
    }
  }	
}

.pageControlContainer {
  margin-left: 15px;
  margin-bottom: 50px;
  padding: 10px;
  padding-bottom: 80px;

  .prevPageBtn {
    margin-right: 5px;
  }

  .nextPageBtn {
    margin-left: 5px;
  }
}

.dropDownOverflow {
  height: 75px;
}

.projectCommentButtonContainer {
  padding: 20px;
  margin: 10px;
}
