// Overrides default stying from the common modules.

.ui-progressbar-value.ui-widget-header {
    background: @harp-orange !important;
}

.reprocessMenu .ui-menu-item {
	font-size: .8em !important;
}

.reprocessMenu .ui-menu-item.ui-state-focus a {
    color: white !important;
}

.negativeButton.ui-state-hover {
    color: white;
    .ui-icon {
        background-image: url("images/ui-icons_ffffff_256x240.png");
    }
}

.ui-menu-item.ui-state-focus {
	color: white !important;
}
