.itemValidationReport {
    margin: 1em;
    h3 {
      font-weight: normal;
    }
}

ul.validationErrors {
    font-size: .8em;
    font-family: Verdana, Arial, Helvetica, sans-serif;
}

ul.validationErrors li {
    margin-bottom: 10px;
    text-indent: -6em;
    padding-left: 6em;
}

.errorLevel.WARN {
    color: orange;
}

.errorLevel.ERROR {
    color: #c00;
}

.errorLevel.FATAL {
    color: #300;
}

.emptyValidationReport {
    display: none;
    font-weight: bold;
    color: green;
    margin-left: 20px;
}

.validationStackTrace {
	font-size: 12px;
	color: @gray;
	display: none;
}

.validationReportBody {
	a.showMore {
		display: none;
	}
}
