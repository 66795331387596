.addMetadataNameFields {
	height: 32px;
	label {
		float: left;
		margin-top: 4px;
	}

	input {
		float: right;
		width: 280px;
	}
}

.newMetadataHints {
	clear: both;
	text-align: center;
	font-size: .7em;
	color: red;
}

.newMetadataValuesContainer {
	border: 1px solid black;
	height: 150px;
	overflow-y: auto;
	.metadataValueInput {
		width: 260px;
		margin-left: 2px;
	}
}

.metadataFilterOptionSelectors {
	border: 1px solid black;
	padding-top: 10px;
	padding-bottom: 10px;
	#metadataOptionsLeft {
		width: 150px;
		float: left;
		#metadataOptionsLeftLeft {
			float: right;
		}
	}

	#metadataOptionsRight {
		width: 45%;
		float: right;
	}
}

.ui-autocomplete {
	z-index: 1000 ! important;
}

.ui-autocomplete-loading {
	background: white url("../../images/urlLoading.gif") right center no-repeat;
}
