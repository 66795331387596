.projectItemPreview {
    margin: @preview-margin;
    padding: @preview-padding;

    background-color: @preview-bg;
    color: @preview-fg;

    font-family: @font-preview;

    .topic, .map {
        .title {
            margin-top: 0;
        }
    }

    .title, h1, h2, h3, h4, h5 {
    	font-family: @font-preview-title;
    }

    // We need to contextualize 'pre' because text-based previews are a <pre>
    // directly inside the tab wrapper, and we don't want to affect that color.
    * pre {
        border: @code-border;
        color: @font-code-color;
        background-color: @code-bg;
        margin-left: 3em;
        padding: .5em;
        margin-right: 3em;
        font-family: @font-code;
        font-size: @font-code-size;

    	.title, h1, h2, h3, h4, h5 {
	    	font-family: inherit;
    	}
    }

    .noteHeader {
        text-transform: capitalize;
        margin: 1em 0 1em 0;
    }

    .titlealts {
    	display: none;
    }

    .note {
        margin: 10px 3em 10px 3em;
        margin-left: 3em;
        margin-right: 3em;
        padding: 0 1em .5em 1em;
        border: solid 1px black;
        background-color: @gray-lightest;
        .shadow-outer(4px, 8px);
    }

    .cite {
        font-style: italic;
    }

    .uicontrol, .userinput {
        font-weight: bold;
    }

    pre.lines {
        border: none;
        background: inherit;
        font-family: @font-preview;
        font-size: inherit;
        padding: 0;
        margin: 0 0 10px 0;
        color: inherit;
    }

    .tm {
        display: inline;
    }

    dt {
        font-style: italic;
        margin-top: .6em;
    }

    table {
        border-collapse: collapse;
    }

    td {
        padding: 3px;
    }

    .map {
        ul#toc,ul#toc ul {
            list-style-type: none;
            padding-left: 2em;
        }

        ul#toc li {
            margin-bottom: .2em;
        }
    }

    .draft-comment {
        background-color: #eee;
        color: #666;
        padding: .3em;
        font-style: italic;
    }

    pre.textPreview {
        border: none;
        background-color: inherit;
    }

    .line-through {
      text-decoration: line-through;
    }

    .related-links {
    	margin-top: 1em;

    	.parent-links {
    		margin-top: 1em;
    	}

    	.other-links h4 {
    		margin-bottom: 4px;
    	}

    	ol.child {
    		list-style-type: none;
    		li {
    			margin-bottom: .4em;
    		}
    		a {
    			font-weight: bold;
    		}
    	}
    }
}
