.analyticsPanel {
  &.tab {
    padding: 1em;
  }

  .analyticsTotal {
    font-weight: bold;
    padding: 5px 0;
    font-size: 1.2em;
  }

  .analyticsChart:empty {
      display: none;
  }

  .analyticsChart {
    width: @analytics-chart-width;
    border: solid 1px black;
    display: inline-block;
    margin: 0 5px 5px 0;

    &.wide {
      width: (@analytics-chart-width * 2) + 10;
      clear: both;
      float: none;
      .body>canvas {
        width: (@analytics-chart-width * 2) + 10;
      }

      @media print {
        width: 100%;
      }
    }

    .header {
      font-size: 1.2em;
      color: @harp-blue;
      border-bottom: solid 1px @harp-gray;
      padding: 5px;
    }

    .body {
      height: @analytics-chart-height;
      overflow: hidden;
      padding: 5px;

      >canvas {
        width: @analytics-chart-width;
        height: @analytics-chart-height;
      }
    }

    .legend {
      width: 100%;
      overflow-x: auto;

      >table {
        font-size: .65em;
        width: 100%;
        border-collapse: collapse;
        border: solid 1px @harp-gray;
        td {
          border: solid 1px @harp-gray;
  
          &.tally {
            text-align: right;
          }
        }
      }
    }
  }
}
