@harp-gray: #e5e5e5;
@harp-blue: #2F87B5;
@harp-orange: #ff6e00;
@harp-text: #4f4f4f;

@shade1: @harp-gray;
@shade1-darker: darken(@shade1, 0%);
@shade1-lighter: lighten(@shade1, 10%);
@shade1-lighterer: lighten(@shade1, 50%);
@shade1-lightest: lighten(@shade1, 60%);

@shade2-dark: @harp-orange;
@shade2: lighten(@shade2-dark, 0%);
@shade2-light: @harp-blue;

@negative-color: #cd0a0a;

@white: white;
@black: black;

@gray: #999;
@gray-dark: #666;
@gray-light: #ccc;
@gray-lighter: #ddd;
@gray-lightest: #eee;

@header-bg: @shade1-darker;
@header-color: black;
@header-border: solid 3px @harp-blue;
@header-logo-color: @shade2;

@form-heading-color: @shade2;

@main-list-hover-bg: @shade1;
@file-upload-flash-bg: lighten(@harp-orange, 20%);

@special-treenode-color: @harp-text;

@panel-resize-hover-color: lighten(@harp-orange, 40%);

@font-title: LatoWeb,"Helvetica Neue",Helvetica,Arial,sans-serif;
@font-body: @font-title;
@font-preview: "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-preview-title: "Helvetica Neue", Helvetica, Arial, sans-serif;
@preview-bg: white;
@preview-fg: black;

@font-code: Monaco, Consolas, "Courier New", Courier, monospace;
@font-code-size: .8em;
@font-code-color: white;
@code-border: dashed 1px @shade1-darker;
@code-bg: @harp-text;

@form-radius: 0;
@input-radius: 0;
@input-outline-color: @harp-orange;
@form-field-width: 248px;
@form-field-color: black;
@form-field-border: black;

@preview-margin: 35px 0 0 0;
@preview-padding: 1em;

@dataTable-pageButton-color: white;
@dataTable-pageButton-color-disabled: @gray-lighter;
@dataTable-border-color: @shade1-darker;
@dataTable-info-color: @harp-text;
@dataTable-cell-padding: 3px 5px;
@dataTable-font-size: .8em;
@dataTable-even-color: @harp-gray;
@dataTable-even-color-highlight: lighten(@dataTable-even-color, 5%);
@dataTable-odd-color: #f6f6f6;
@dataTable-odd-color-highlight: white;

@notification-bg: fade(white, 90%);
@notification-fg: @harp-blue;
@notification-border: solid 2px @harp-orange;
@notification-radius: 5px;
@notification-font: @font-body;
@notification-font-size: .8em;
@notification-shadow: 5px 5px 5px @gray-dark;

@notification-error-bg: fade(red, 70%);
@notification-error-fg: black;
@notification-error-border: solid 1px red;

@tabs-padding: 0;
@tabs-bottom-border: solid 3px @shade2;

@monitorChart-mainFillColor: lighten(@harp-blue, 40%);
@monitorChart-mainLineColor: black;
// ITEM_PROCESSING_REQUESTED fill
@monitorChart-itemFillColor: #e4a95f;
// CONTEXTUALIZATION_REQUESTED fill
@monitorChart-cxtFillColor: #f9ba4c;

@analytics-chart-width: 400px;
@analytics-chart-height: 200px;
