.fileFeedbackTab {
  padding: 15px;

  .votesBox {
    border-style: solid;
    border-width: 2px;
    &.up {
      border-color: green;
      .icon {
        color: green;
      }
    }
    &.down {
      border-color: red;
      .icon {
        color: red;
      }
    }
    border-radius: 7px;
    padding: 15px;
    font-size: 50px;
    font-weight: bold;
    display: inline-block;
    width: 160px;
    &.up {padding-right: 8px;}

    .icon svg {
      width: 50px;
      height: 50px;
    }
  }

  .resultMessage {
    margin-bottom: 1em;
    .thumbIcon {
      height: 1em;
      width: 1em;

      &.thumbsDown {
        color: red;
      }

      &.thumbsUp {
        color: green;
      }
    }
    .identification {
      font-weight: bold;
    }
  }
}
