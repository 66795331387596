#portalUrlPrefixEcho {
  font-size: 0.7em;
}

.urlErrorHint {
  font-size: 0.7em;
  color: red;
}

.addPortalToProjectButton {
  float: right;

  .ui-icon {
    margin-bottom: 5.5%;
  }
}

.portalAdminUI {
  .ui-selectmenu-button {
    height: auto;
  }

/*  &.framesetUI .ui-layout-west ul.links {
    li.selected, li.selected:hover {
      background: @harp-orange;
    }

    li:hover {
      color: @harp-orange;
    }
  }*/

  .featureList {
    list-style-type: none;
    padding-left: 0;
  }

  .portalReportBody {
    min-height: 320px;

    .portalReportCanvas {
      width: 300px;
      height: 300px;
      float: left;
      margin: 10px 10px 0 0;
    }

    .portalReportTableWrapper {
      font-size: 0.8em;
    }
  }

  .samlListsContainer textarea,.oauthListsContainer textarea {
    width: 500px;
    height: 160px;
    font-size: 0.8em;
  }

  .portalMetadataFilterTabs {
    .userFilterTable {
      input {
          width: 100%;
      }

      td {
          padding: 0 5px;
      }
    }

    .ui-tabs-nav {
      font-size: .7em;
    }
  }

  .editable {
    display: inline-block;
  }

  .reportsHeader {
    margin-bottom: 0;
    margin-top: 0;
  }

  .reportsDownloadContainer {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }

  #portalReportsTabset, #portalDataTabset {
    ul {
      font-size: 0.7em;
    }

    .ui-tabs-panel {
      padding: 10px;
    }
  }

  .portalThemeSelectorWrapper {
    span {
      top: 0;
    }

    #portalThemeSelector-button {
      width: auto;
    }
  }

  .contactDetailsTableEntry {
    margin-bottom: 5px;
    display: block;
  }

  .portalDetailsWrapper {
    border-radius: 5px;
  }

  button.Add {
    span.ui-button-icon.ui-icon.ui-icon-plus {
      margin-bottom: 5.5%;
    }
  }

  #dynamicFilters>.help {
    font-size: .8em;
    margin-bottom: 18px;

    .propertiesWrapper {
      border: solid 1px black;
      border-radius: 5px;
      display: inline-block;
      padding: 5px;
      margin-bottom: 10px;
    }

    .ui-accordion-content {
      padding: 10px;

      pre {
        padding: 10px;
        border-radius: 5px;
        border: solid 1px black;
        background-color: #eee;
      }

      pre:empty {
        display: none;
      }

      input#userName {
        width: 200px;
      }
    }
  }
}

#portalFeaturesWrapper {
  .robotsTxtBehavior {
    ul {
      list-style-type: none;
      padding: 0;
    }
    input {
      margin-right: 10px;
    }
    input, label {
      float: left;
      max-width: 600px;
      margin-bottom: 1em;
    }
    li {
      clear: both;
    }
  }
}

@portal-projects-border-color: #999;

.portalProjectsListWrapper {
  border: solid 1px @portal-projects-border-color;
  border-radius: 7px;
  display: inline-block;

  .clear {
    clear: both;
  }

  .portalProjectDeleteButton {
    float: right;
  }

  .togglePortalProjects {
    float: right;
    font-size: .8em;
    color: @harp-blue;
  }
  
  .sidebyside {
    width: 400px;
    float: left;

    h3 {
      margin: 10px;
    }

    .content {
      height: 300px;
      overflow: auto;
      border-top: solid 1px @portal-projects-border-color;

      .listEntry {
        padding: 3px 10px;

        .ownerName {
          font-size: .8em;
        }

        &:hover {
          background-color: lighten(@harp-blue, 45%);
        }

        &.portalEntry {
          display: none;
          font-style: italic;
          color: rgba(0, 0, 0, .6);
        }
      }
    }
  }

  .candidatesList {
    border-left: solid 1px @portal-projects-border-color;

    .content {
      background-color: #eee;
    }
  }

  a.nameLink, a.nameLink:visited {
    color: inherit;
  }

  a.addremove {
    font-size: .8em;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }

    &.addButton {
      color: green;
    }
    &.removeButton {
      color: red;
    }
  }
}

#portalThemePortalListView {
  margin: 1em;
  .ownerList > li {
    font-weight: bold;
    >img {
      height: 1em;
    }
  }
  .portalList {
    font-weight: normal;
  }
}

@portalDataView-height: 400px;
@portalUserKeys-width: 250px;

#portalDataView {
  .openProjectButtonWrapper {
    text-align: right;
    .margin-bottom-5;
  }

  #pdtKeystoreData {
    .main {
      display: flex;

      .keys {
          flex-basis: @portalUserKeys-width;

        select {
          height: @portalDataView-height;
          min-width: @portalUserKeys-width;
          max-width: @portalUserKeys-width + 150px;
          font-size: .8em;
        }
      }

      .values {
        flex-grow: 1;
        overflow: auto;
        border: solid 1px black;
        padding: 5px;
        height: @portalDataView-height - 10px;

        pre {
          margin: 0;
        }
      }
    }
  }

  #pdtIndexedData {
    .searchBar {
      background-color: white;
      padding: 5px;
      margin: -10px -10px 10px -10px;
      border-bottom: solid 3px black;
      display: flex;
      align-items: center;
      -ms-flex-align: center;
      >* {
        margin: 0 3px;
      }
      .pdtDownloadButton {
        // Flex messes up the height somehow.
        height: 22px;
      }
      input {
        flex-grow: 1;
      }
    }

    #pdtIndexedQueryResults {
      display: flex;
      flex-direction: column;
      font-family: monospace;
      .row {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        border-bottom: solid 1px @gray-dark;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: 0;
        }

        .entry {
          display: flex;
          margin-right: 3px;
          .key, .value {
            padding: 3px;
            border: solid 1px black;
          }
  
          .key {
            font-weight: bold;
            background-color: @gray-light;
          }

          .displayData {
              display: none;
          }
        }
      }
    }
  }
}

.portalReportsHeader .datePickerWrapper {
    display: inline-block;
    vertical-align: bottom;
}

.portal-data-inspector {
    white-space: pre;
    font-family: monospace;
}

.numberInput {
    width: 60px;
}
