.framesetUI {

  .heading {
    font-size: 24px;
    float: left;
    overflow: hidden;
    margin: 10px 0;
    color: black;
    white-space: nowrap;
    overflow: visible;

    .icon {
      margin-right: 2px;
      color: @harp-blue;
    }
  }

  .frame-container {
    position: absolute;
    top: 100px;
    bottom: 20px;
    left: 10px;
    right: 10px;
  }

  .ui-layout-west {
    background-color: #eee;
    padding: 0;

    ul.links {
      list-style: none;
      padding-left: 10px;
      font-family: @font-title;
      font-size: 1.2em;
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        cursor: pointer;
        padding: 8px;

        &.selected, &.selected:hover {
          color: white;
          background: @harp-blue;
        }

        &:hover {
          background: darken(#eee, 15%);
        }
      }
    }
  }

  .frame-right {
    .mainSelectableList {
      max-width: 600px;
    }
  }

  &.projectViewer {
    .ui-layout-center {
      padding: 0;
    }

    .ui-layout-west {
      background-color: inherit;
    }
  }

}
