.formButtons input[type='submit'],.formButtons button {
}

.formtable,.contentPanel {
    border: solid 3px black;
    border-radius: @form-radius;
    .shadow-outer;
    margin: 40px auto 40px auto;
    background-color: white;
    border-spacing: 0px;
}

.contentPanel {
    width: 400px;
}
.formtable td,.contentPanel {
    padding: 6px;
}

.formtable td.newUserCheckBoxCell {
    padding: 0 0 0 .3em;
}

input.newUserInput {
    border-radius: 0 !important;
    padding: 3px !important;
}

.formtable .newUserFormField {
    padding: 0 6px 0 6px;
}

.formtable h2,.contentPanel h2 {
    font-size: 2.2em;
    color: @form-heading-color;
    margin: 0px 4px 0px 4px;
    font-weight: normal;
}

.formtable label {
    font-size: 1.1em;
}

.formtable .newUserLabel {
    font-size: .7em;
    margin-left: .3em;
}

table.paddedForm td {
    padding-bottom: 10px;
}

label.disabled {
    color: #999;
}

.formtable label em {
    color: red;
    font-weight: normal;
}

.formtable input[type='text'],.formtable input[type='password'] {
    font-size: 1em;
    border-radius: @input-radius;
    padding: 6px;
    font-family: @font-body;
    border: solid 1px black;
}

*:focus {
	outline-color: @input-outline-color;
}

.formheader {
    border-bottom: solid 1px black;
    text-align: center;
}

.formtable .formButtons {
    text-align: center;
    border-top: solid 1px black;
}

.contentPanel .formButtons {
    text-align: right;
}

.contentPanel .formButtons button {
    width: 100px;
}

button {
    white-space: nowrap;
}

.formError {
    color: red;
    font-size: .8em;
}

.formSuccess {
    color: @shade1;
    font-size: .8em;
}

.formInfo {
    color: black;
    font-size: .8em;
}

.formButtons input[type='submit'],.formButtons button {
    font-size: 1.1em;
    border-radius: @input-radius;
    margin: 10px 0 10px 0;
    padding: 5px;
    color: @shade2-light;
    .gradient;

    &:disabled {
        color: #888;
        background-color: #ccc;
        border: solid 3px #ccc;
    }
}

.formtable td.finePrint {
    font-size: .6em;
}

span.formlabel {
}

span.formvalue {
}

label.formlabel {
    cursor: pointer;
}

.ui-menu {
	font-size: .8em;

	a {
		cursor: default;
	}
}

#accountHelpTable {
	width: 340px;
}
