@portalParameterForm-inputFontSize: .8em;
.portalParameterForm {
  h3 {
    margin-left: 10px;
    font-weight: normal;
  }
  .ui-tabs-nav {
    font-size: .7em;
  }
  .ui-selectmenu-button {
    width: auto;
  }
  .widget {
    padding: 5px;
  }
  .group > .instructions {
    margin: 10px;
  }
  .instructions {
    font-size: .8em;
    font-style: italic;
  }
  .widget.number {
    .ui-spinner {
      margin-top: 5px;
    }
    input {
      width: 50px;
      margin-right: 24px;
      font-size: @portalParameterForm-inputFontSize;
    }
    a {
      width: 22px;
      span.ui-button-text {
        span.ui-icon {
          left: 3px;
        }
      }
    }
  }
  .widget.string {
    input {
      margin-top: 5px;
      border-radius: 5px;
      padding: 5px;
      width: 300px;
      font-size: @portalParameterForm-inputFontSize;
    }
  }
  .widget.text {
    .textFormatSelectorWrapper {
      margin-bottom: 10px;
    }
    .textInputWrapper {
      textarea {
        width: 99%;
        height: 150px;
        font-size: @portalParameterForm-inputFontSize;
      }
    }
    span.ui-selectmenu-button {
      top: 0;
      margin-left: 10px;
    }

  }
  .widget.themeFile {
    label {
    }
    span.ui-selectmenu-button {
      top: 0;
      margin-top: 5px;
    }
  }
  .widget.select.single {
    span.ui-selectmenu-button {
      top: 0;
      margin-top: 5px;
    }
  }
  .widget.select.multi {
    .connectedSortable {
      cursor: -webkit-grab;
      cursor: -moz-grab;
      font-size: @portalParameterForm-inputFontSize;
      top: 0;
      border: 2px solid lightgray;
      display: inline-block;
      vertical-align: top;
      width: 322px;
      min-height: 20px;
      list-style-type: none;
      margin-top: 10px;
      padding: 5px 0 0 0;
      margin-right: 10px;
      li {
        border: 1px solid #eee;
        margin: 0 5px 5px 5px;
        padding: 5px;
      }
    }
    .connectedSortable.ui-sortable-disabled {
      cursor: default;
    }
  }
  .widget.checkbox {
    label {
      margin-right: 10px;
    }
  }
  .widget.linkList {
  	.addFirstLinkButton {
  		margin-top: 10px;
  		margin-bottom: 10px;
  		width: 100;
  		height: 30px;
  	}
    .linkEntry {
      margin-top: 5px;
      span.ui-selectmenu-button {
        margin-left: 5px;
        position: static;
        width: 100px;
      }
      button {
        span.ui-button-text {
          text-align: center;
          padding-right: 0;
        }
      }
      button.ui-button-text-only span.ui-button-text {
        text-align: center;
        padding: 0;
      }
      .removeLinkEntryButton {
        margin-left: 2px;
      }
      .addLinkEntryButton {
        margin-left: 10px;
      }
      input {
        font-size: @portalParameterForm-inputFontSize;
        border-radius: 5px;
        padding: 5px;
        width: 200px;
        margin-right: 5px;
      }
    }
  }
}

.portalThemeConfigButtons {
	float: right;
	margin: 5px;
	margin-top: 15px;
	margin-right: 0;
}
.overflowSelect {
	height: 200px;
}
