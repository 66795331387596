.td-sortable {
  display: inline-table;

  >.sortable-item {
    display: table-row;
    margin: 0 3px 3px 3px;
    height: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    >.sortable-item-widget input {
      display: table-cell;
    }

    >.sortable-list-controls>.ui-button {
      font-size: .8em;
      padding: 4px;
      margin-top: -6px;
      display: table-cell;
    }

    .sortable-grabber {
      cursor: move;
    }

    .key-cell {
      display: inline-block;
      input.key.blank {
        background-color: #f88;
      }
    }
  }

  &.sortable-multimap {
    .mmap-value-list {
      margin: 0 5px 15px 0;
      border-right: solid 2px @harp-blue;
      padding-right: 5px;
      .ui-button {
        padding: 0;
      }
    }

    .sortable-item:last-child .mmap-value-list {
      margin-bottom: 0;
    }
  }

  &.sortable-labels .sortable-item-label {
    display: inline-block;
    margin: 3px;
  }
}
