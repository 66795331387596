.objectMetadataTable {
    margin: 0;
    clear: both;
    
    td.metadataCascadesColumn {
        text-align: center;
    }
}

.metadataEditorButton {
    display: inline-block;
    cursor: pointer;
}

.editMetadataColumn {
    width: 40px;
}

.objectMetadataButtonContainer {
    padding: 1em;
    float: right;
}