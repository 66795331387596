
.dialogFormField {
    width: @form-field-width;
    padding: 4px;
    font-family: @font-body;
    font-size: .8em;
    color: @form-field-color;

    &:disabled {
        background-color: #eee;
    }
}

.dialogInputField, .dialogDisplayField {
    margin-bottom: .3em;

    label {
        font-size: .8em;
        display: block;

        &.nobold {
            font-weight: normal;
        }

        &.inline {
            display: inline;
        }
    }

    input[type=text], input[type=number], input[type=password], select, textarea {
        .dialogFormField;
    }

    span.tip, span.formError {
        margin-left: 1em;
    }

    input, textarea {
        border-radius: @input-radius;
        border: solid 1px @form-field-border;
    }

    span.ui-icon {
        display: inline-block;
        position: relative;
        top: 2px;
    }

    span.requiredIndicator {
        color: red;
    }
}
