.robotsTxtEditor {
  width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;

  .codeview {
    width: 100%;
    height: 250px;
    resize: none;
    background-color: #eee;
    font-family: Menlo, Monaco, 'Courier New', monospace;
  }
}