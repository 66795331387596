body,html {
    font-family: @font-body;
    padding: 0px;
    margin: 0px;
    margin-bottom: 15px;
    background-color: #fff;
}

h1,h2,h3,h4,h5,h6 {
    font-family: @font-title;
}

.darkGreenGradient {
    .gradient;
}

#pagebody {
    padding: 8px;
}

a.appLink {
    font-size: .9em;
}

a.appLink:visited {
    color: blue;
}

/********************
 PROJECT LIST
 ********************/
.projectList {
    margin-left: auto;
    margin-right: auto;
    width: 700px;
}

.mainSelectableList h2 {
    display: inline;
    font-weight: normal;
}

.mainSelectableList .listHeader {
    border-bottom: solid 1px #999;
    margin-bottom: .6em;
}

.listHeader .button {
    color: #999;
    text-decoration: underline;
    cursor: pointer;
    font-size: .8em;
    margin-left: 1em;
    outline: 0;
    background: none;
    border: 0;
}

.hoverClass {
    text-decoration: underline;
    cursor: pointer;
}

.projectList .listWrapper,.orgManagement .mainSelectableList {
    width: 500px;
}

.Row_LinkToPortal {
  height:1em;
}

.deleteRepoButton {
  margin-top: -3px;
  float: right;
}

.orgManagement .mainSelectableList {
    margin: 20px;
}

.projectList .homeNavButtons {
    width: 200px;
}

.projectListSortPlaceholder {
    font-size: 1em;
    line-height: 1em;
    padding: 5px;
}

.projectGrouping {
    margin-top: 1em;
}

.listHeader .icon {
  color: @harp-orange;
  margin-right: 5px;
  svg {
    max-width: 1.5em;
    max-height: 1.5em;
  }
}

.mainPageEntry .name .icon {
  color: @harp-blue;
  max-height: .9em;
}

.linkToProject{
  float: right;
  color: gray;
} 

.favoriteButton.favorited{
  fill: gold;
  stroke: gold;
  float: right;
  margin-left: 12px;
  margin-right: 4px;
  margin-bottom: 2px;
}

.favoriteButton{
  fill: #999;
  stroke: #999;
  float: right;
  margin-left: 12px;
}

.favoritesProject {
  border-bottom: solid 3px @harp-blue;
  margin-bottom: 2em;

  .listWrapper{
    padding-left: 0px;
  }
}

/********************
 MAIN PAGE
*********************/
/* Identical to PROJECT LIST classes, but renamed */
.mainPage {
    margin-left: auto;
    margin-right: auto;
    width: 950px;
}

.mainPageHeading {
    font-size: 1.8em;
    font-weight: normal;
    color: @header-color;
    text-align: center;
    padding: 6px;
    border-bottom: @header-border;
    font-family: @font-title;

    .icon svg {
      color: @harp-blue;
      max-height: .75em;
    }
}

.mainPage h2 {
    display: inline;
    font-weight: normal;
}

.mainPage .listHeader {
    border-bottom: solid 1px #999;
    margin-bottom: .6em;
}

.mainPage .listWrapper {
    width: 750px;
}

.mainPage .homeNavButtons {
    width: 200px;
}

.mainPageEntry {
  font-size: 1em;
  text-align: left;
  border-bottom: solid 1px #ddd;
  padding: 5px;
  cursor: pointer;
  display: block;

  .ui-icon-grip-dotted-vertical {
    cursor: grab;
  }

  &.ui-sortable-helper {
    .ui-icon-grip-dotted-vertical {
      cursor: grabbing;
    }
  }
}

.mainPageHeaderFilter {
  width: 100%;
  border-width: 0 0 1px 0;
  padding: 4px 0;
  outline: none;
  color: @harp-blue;
  font-size: 1.2em;
  font-style: italic;
}

.mainPageHeaderFilter.disabled {
  display: none;
}

.projectGrouping.organization .mainPageEntry.OWNER {
  font-weight: bold;
}

.mainPageEntry.ui-state-hover {
	font-weight: normal !important;
	border-width: 0 0 1px 0 !important;
}

.mainPageSortPlaceholder {
    font-size: 1em;
    line-height: 1em;
    padding: 5px;
}

.homeNavButtons {
    vertical-align: top;
}

.listView {
    vertical-align: top;
}

.ui-button {
    font-size: .7em !important;
}

ul.ui-tabs-nav a:focus {
    outline: 0;
}

.homeNavButtons div.ui-button {
    margin-left: 20px;
    margin-bottom: 5px;
    display: block;
    width: 180px;

    // Override the default Bootstrap stuff
    background: @harp-blue;
    border: none;
    font-size: 1rem !important;

    &:hover {
      background: darken(@harp-blue, 10%);
      color: white;
    }

    &.ui-state-disabled {
      background: black;
      opacity: 1;
    }

    .icon > svg {
      float: left;
      margin-top: 2px;
    }
}

.icon > svg {
  max-height: 1em;
  max-width: 1em;
}

.homeNavButtons hr {
    margin-left: 20px;
}

.canted {
    .rotate(1deg);
}

/*********************
 Breadcrumbs
 *********************/
.breadcrumbs {
    font-size: .8em;
}

.breadcrumbSeparator {
    color: red;
}

.breadcrumbs .clickable {
    cursor: pointer;
    text-decoration: underline;
    color: #999;
}

.breadcrumbs .clickable:hover {
    color: #555;
}

/*********************
 Project Contents Tree Control
 *********************/
.projectTree {
    padding-left: 0;
    margin-top: 4px;
    white-space: nowrap;
}

.projectTree .subtree {
    padding-left: 18px;
}

.ui-layout-west {
    overflow: auto;
}

.projectTree .expandme {
    width: 16px;
    height: 16px;
    display: inline-block;
}

.projectTreeItem {
    list-style: none;
}

.projectTree .ui-icon {
    display: inline-block;
    position: relative;
    top: .2em;
}

.projectTree .selectable {
    padding-right: .1em;
    border-radius: 3px;
}

.projectTree .label {
    cursor: pointer;
    font-size: .9em;
    white-space: nowrap;
    display: inline-block;
    padding-right: 6px;
}

.tree-wrapper.ui-widget-content {
    border: none;
}

.projectTree .newFolderItem .label,.projectTree .uploadFilesItem .label,.projectTree .assembliesItem>.item .label
{
    font-style: italic;
    color: @special-treenode-color;
}

.projectTree .uploadFilesItem .ui-state-default .label,.projectTree .assembliesItem>.item .ui-state-default .label
{
    color: white;
}

.frame-right {
    vertical-align: top;
    height: 100%;
}

.projectDetailsTabset {
  .ui-tabs-nav {
      font-size: .7em;
  }

  &.ui-widget-content {
      border: none;
  }

  .buttonbar {
      background-color: rgba(255, 255, 255, 0.8);
      border-bottom: solid 1px black;
      padding: 6px 1em;
  }

  .buttonbar button:focus {
      outline: 0;
  }

  .configInfo {
    font-size: .8em;
    padding: 3px 0;
    background-color: #ffc;
  }
}

#renameProjectInput {
    width: 255px;
}

/*Feedback text entry*/
.itemPreviewView textarea {
    font-family: @font-code;
    font-size: .8em !important;
    background-color: @gray-lightest;
}

.projectTransforms h3 {
    margin: 8px 0px 8px 0px;
}

.projectTransformsButtons button {
    width: 200px;
    margin-bottom: 4px;
}

.projectTransformsButtons button:last-child {
    margin-bottom: 0;
}

.itemMetadata {
    padding: 1em;

    .metadataTable {
        max-width: 600px;

        th.category {
            text-align: left;
            color: @header-color;
            padding-left: 8px;
            border-bottom: @header-border;
            padding: 4px;
            font-weight: normal;
            font-size: 1.2em;
        }

        th.mdKey {
            text-align: right;
            padding-right: 8px;
            white-space: nowrap;
        }

    }
}

.projectUrlConfig {

  &.ui-widget.ui-widget-content {
    border: 0;
    background: inherit;
  }

  >div {
    padding: 0 10px !important;
    background-color: inherit;
  }

  #urlPathFormula {
    width: 500px;
  }
}

.urlConfig {
  .urlPath {
    text-decoration: underline;
  }
}

.valueList {
    border: solid 1px black;
}

.projectActivityView .job {
    margin-bottom: 10px;
    border: solid 1px black;
    background-color: @shade1-darker;
}

.jobFile {
    padding-left: 4px;
    background-color: @header-bg;
    color: @header-color;
}

.jobType.EXECUTING {
    background-color: #fff;
}

.jobType.SUCCESS {
    background-color: #d1f2cb;
}

.jobType.FAILURE {
    background-color: #fcc;
}

.fileActivityView {
  padding: 10px;
}

.phaseIndicator {
    font-size: 1.4em;
}

.phaseIndicator .icon {
  vertical-align: top;
  color: @harp-blue;
}

.itemRelationships .unresolved {
    color: #f00;
}

.errorLevel {
    font-weight: bold;
}

ul.treeSelect {
  list-style-type: none;
  padding-left: 0;

  ul.selectionSubtree {
    list-style-type: none;
    padding-left: 25px;
  }
}

.dialogInputField {
  input[type=text], select {
    box-sizing: border-box;
    width: 100%;
  }
}

/********************
 METADATA FIELDS
*********************/
.metadataTable {
  width: 100%;
}

th.tableHead {
  text-align: left;

}

.deleteButtonContainer {
  width: 20px
};

.objectMetadataTable {
  text-align: left;
  tr:nth-child(even) {background-color: #f2f2f2;}
  td {
    .ui-button{
      margin-right : 20px;
    }
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.red {
  color: red;
}

.green {
  color: green;
}

.blue {
  color: blue;
}

.small {
  font-size: .9em;
}

.smaller {
  font-size: .8em;
}

.smallest {
  font-size: .7em;
}

.metadataRemoveField {
  width: 20px;
}

.downloadFormContainer {
  padding: 10px;
}

.reprocessMenu {
  display: none;
  position: absolute;
  width: 250px;
  z-index: 1000;
}

.progressBarWrapper {
  display: none;
}

#projectUrlConfigSlug .urlResetButton {
  display: none;
}

.emptyValidationSummary {
  display: none;
}

.messageLine {
  display: none;
}

.shortcutsRanges {
  display: flex;
  margin-bottom: 20px;
}

.shortcutsRanges > * {
  flex: 1;
}