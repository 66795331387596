.w-full {
  width: 100%;
}

.w-4 {
  width: 1rem; // 16px
}
.w-5 {
  width: 1.25rem; // 20px
}

.h-4 {
  height: 1rem;
}

.text-right {
  text-align: right;
}

.ml-4 {
  margin-left: 1rem; // 16px
}
.ml-5 {
  margin-left: 1.25rem; // 20px
}

.mr-2\.5 {
  margin-right: 0.625rem; // 10px
}

.mt-7 {
  margin-top: 1.75rem; // 28px
}
.mt-8 {
  margin-top: 2rem; // 32px
}

.p-2\.5 {
  padding: 0.625rem; // 10px
}

.float-right {
  float: right;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.clear-both {
  clear: both;
}
