#notificationCenter {
  position: fixed;
  right: 10px;
  top: 40px;
  width: 510px;
  overflow: hidden;
  z-index: 99999;

  .notification {
    border: @notification-border;
    border-radius: @notification-radius;
    background-color: @notification-bg;
    color: @notification-fg;
    font-family: @notification-font;
    font-size: @notification-font-size;
    width: 390px;
    margin-bottom: 10px;
    min-height: 50px;
    padding: 5px;
    box-shadow: @notification-shadow;

    &.error {
      border: @notification-error-border;
      background-color: @notification-error-bg;
      color: @notification-error-fg;
    }

    .closeButton {
      float: right;
      cursor: pointer;
    }
  }
}
