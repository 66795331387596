ul.ui-tabs-nav {
    border-bottom: @tabs-bottom-border;
    
    .ui-tabs-vertical > & {
        border-bottom: 0;
        border-right: @tabs-bottom-border;
    }
}

.ui-tabs .ui-tabs-panel {
    padding: @tabs-padding;
}