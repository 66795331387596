body div.dataTables_wrapper {
    .dataTables_paginate {
        .fg-button {
            color: @dataTable-pageButton-color !important;
            &.ui-state-disabled {
                color: @dataTable-pageButton-color-disabled !important;
            }
        }
    }
    
    .dataTables_info {
        color: @dataTable-info-color;
    }
    
    table.dataTable {
        border: solid 1px @dataTable-border-color;
        
        th, td {
            padding: @dataTable-cell-padding;
        }
        
        tbody td {
            font-size: @dataTable-font-size;
        }
        
        tr.odd {
            background-color: @dataTable-odd-color;
            
            td.sorting_1 {
                background-color: @dataTable-odd-color-highlight;
            }
        }
        
        tr.even {
            background-color: @dataTable-even-color;
            
            td.sorting_1 {
                background-color: @dataTable-even-color-highlight;
            }
        }
    }
}
