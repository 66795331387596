.fileUpload .dropZone {
    width: 200px;
    height: 200px;
    padding: .4em;
    line-height: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.fileUpload .uploadButtonWrapper .ui-button {
    cursor: pointer;
    color: white;
}

.fileUpload .fileUploadControl {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    filter: ~"alpha(opacity = 0)";
    cursor: pointer;
    width: 100%;
}

.fileUpload .uploadButtonWrapper,.fileUploadOr,.fileUpload .dropZone {
    margin-bottom: 20px;
}

.fileUpload table {
    width: 100%;
}

.fileUpload td {
    padding: 5px;
    vertical-align: top;
}

.uploadWidgets {
    text-align: center;
    width: 230px;
}

.dropZone {
    .shadow-inner;
    cursor: default;
}

.dropZone.dragover {
    background-color: #ffe;
    border: solid 1px #ff0;
}

.progressBarLabel {
    font-size: 12px;
    text-align: left;
}

.progressBarLabel .fileName {
    font-weight: bold;
}

.uploadErrorList {
    font-size: .7em;
    list-style: none;
}

.uploadError {
    display: inline-block;
    margin-bottom: .6em;
}

.uploadError .itemPath {
    background-color: red;
    color: white;
    padding: 2px;
}

