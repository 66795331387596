.listWrapper.userManagement {
  table.usersTable {
    font-size: .8em;
    width: 100%;

    thead {
      background-color: black;
      color: white;
    }

    tbody tr:nth-child(even) {
      background-color: #eee;
    }

    tbody tr.self {
      font-weight: bold;
    }

    .menu {
      display: none;
      position: absolute;
      z-index: 1000;

      a {
        text-decoration: none;
      }
    }
  }

  .buttons {
    text-align: right;
    padding-top: 10px;
    border-top: solid 1px black;
  }
}
