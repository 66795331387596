.gradient() {
    background: @shade1-darker; /* Old browsers */
    background: -moz-linear-gradient(top, @shade1-darker 0%, @shade1 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @shade1-darker),
        color-stop(100%, @shade1)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, @shade1-darker 0%, @shade1 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, @shade1-darker 0%, @shade1 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, @shade1-darker 0%, @shade1 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, @shade1-darker 0%, @shade1 100%);
    /* W3C */
    /*filter: progid:DXImageTransform.Microsoft.gradient(  startColorstr='@shade1-darker',
        endColorstr='@shade1', GradientType=0); /* IE6-9 */
}

.shadow-outer(@off: 8px, @fade: 15px, @color: fade(#000, 60%)) {
    -moz-box-shadow: @off @off @fade @color;
    -webkit-box-shadow: @off @off @fade @color;
    box-shadow: @off @off @fade @color;
}

.shadow-inner(@off: 5px, @fade: 15px, @color: fade(#000, 60%)) {
    -moz-box-shadow: inset @off @off @fade @color;
    -webkit-box-shadow: inset @off @off @fade @color;
    box-shadow: inset @off @off @fade @color;
}

.rotate(@amount) {
    transform: rotate(@amount);
    -ms-transform: rotate(@amount); /* IE 9 */
    -moz-transform: rotate(@amount); /* Firefox */
    -webkit-transform: rotate(@amount); /* Safari and Chrome */
    -o-transform: rotate(@amount); /* Opera */
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.logout {
  vertical-align: top;
  text-align: right;
  padding-right: 10px;
}
