.ownershipExchangePrompt {
    border: 3px solid @shade1;
    border-radius: 5px;
    text-align: center;
    padding: 3px;
}

.membershipManager {
  margin-top: 20px;
  margin-left: 20px;
  width: 575px;

  h2 {
    display: inline;
    font-weight: normal;
  }

  .listHeader {
    border-bottom: solid 1px #999;
    margin-bottom: .6em;
  }

  .memberRow {
    clear: both;
    border-bottom: solid 1px #ddd;
    padding: 8px 0 8px 0;
    min-height: 25px;
    font-size: .9em;

    .name {
      float: left;
      white-space: nowrap;
      overflow: visible;
    }
  }

  .level {
    float: right;
    margin-right: 1em;
  }

  .removeMemberButtonContainer {
    float: right;

    button {
      width: 100px;
    }
  }

  .icon {
    color: @harp-orange;
  }
}
