#monitorBody {
	width: 900px;
	margin: 0 auto 30px auto;

	table {
		border-collapse: collapse;
	}

	#chartCanvas {
		border: solid 1px black;
		background-color: #eee;
	}

	#shades {
		display: none;
	}

    .mainFillColor {
        background-color: @monitorChart-mainFillColor;
    }
    .mainLineColor {
        background-color: @monitorChart-mainLineColor;
    }
    .itemFillColor {
        background-color: @monitorChart-itemFillColor;
    }
    .cxtFillColor {
        background-color: @monitorChart-cxtFillColor;
    }

    #colorKey {
        display: none;
        font-size: .7em;

        .swatch {
            display: inline-block;
            width: 1em;
            height: 1em;
            border: solid 1px black;
        }

        .swatchLabel {
            margin-right: 1em;
        }
    }

	table, th, td {
		border: solid 1px black;
		font-size: .9em;
	}

	th {
		background-color: @header-bg;
		color: @header-color;
	}

	td {
		padding: 3px;
	}

	td.eventDetail {
		font-size: 10px;
	}
}
